import { ApprovalStatusPolicy } from "constants/document/ApprovalStatusPolicy";
import { DynamicTableEntity } from "domain/entity/Common/DynamicTableEntity";
import { DocumentPreviewDetailEntity } from "domain/entity/DocumentPreview/DocumentPreviewDetailEntity";
import { DocumentPreviewEntity } from "domain/entity/DocumentPreview/DocumentPreviewEntity";
import { DocumentPreviewSearchCriteria, EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA } from "domain/entity/DocumentPreview/DocumentPreviewSearchCriteria";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { DynamicTableRepository } from "domain/repository/Common/DynamicTableRepo";
import { PrintQueueRepository } from "domain/repository/Common/PrintQueue";
import { RemoteFileInfoRepository } from "domain/repository/Common/RemoteFileInfoRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { DocumentApprovalRepository } from "domain/repository/DocumentApproval/DocumentApprovalRepo";
import { DocumentPreviewRepository } from "domain/repository/DocumentPreview/DocumentPreviewRepo";
import { UserRepository } from "domain/repository/User/UserRepo";
import _ from "lodash";
import { DynamicColumnNameMapping, getDocumentPreviewValue } from "presentation/constant/Common/DynamicColumnNameMapping";
import { DocumentPreviewFirstPrintCriteria, EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA } from "presentation/constant/DocumentPreview/DocumentPreviewFirstPrintCriteria";
import { DocumentPreviewModel } from "presentation/model/DocumentPreview/DocumentPreviewModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { defineColumn } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import BaseViewModel from "../BaseViewModel";

interface DocumentPreviewVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<DocumentPreviewModel>> | ((value: SetStateAction<DocumentPreviewModel>) => void),
    ],
    documentPreviewRepo: DocumentPreviewRepository,
    companyRepo: CompanyRepository,
    chargeTypeRepo: ChargeTypeRepository,
    dynamicTableRepo: DynamicTableRepository,
    documentApprovalRepo: DocumentApprovalRepository,
    userRepo: UserRepository,
    printQueueRepo: PrintQueueRepository,
    remoteFileInfoRepo: RemoteFileInfoRepository,
}

export const DocumentPreviewVM = ({dispatch,documentPreviewRepo,companyRepo, chargeTypeRepo,dynamicTableRepo,documentApprovalRepo,userRepo,printQueueRepo,remoteFileInfoRepo}:DocumentPreviewVMProps) => {
    const [documentPreviewDispatch] = dispatch;

    const loadDropdownOption = async() => {

        await userRepo.getAllUsers().then(users => {
            let newUsers = _.orderBy(users, ["name"]);
            const finalizedByDropdownOption = newUsers?.map((user) => ({
                dropdownLabel: user.name,
                tagLabel: user.name,
                value: user.name,
            })) ?? []

            documentPreviewDispatch(prevState => ({
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    finalizedByDropdownOptions: finalizedByDropdownOption,
                }
            }))
        });

        await printQueueRepo.getPrintQueueForCombox().then(
            printQueues => {
                let printQueueDropdownOptions = printQueues?.map((printQueue) => ({
                    dropdownLabel: printQueue.printQueueName,
                    tagLabel: printQueue.printQueueName,
                    value: printQueue.printQueueName,
                })) ?? []
                printQueueDropdownOptions = _.orderBy(printQueueDropdownOptions, "dropdownLabel");

                documentPreviewDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        printQueueDropdownOptions: printQueueDropdownOptions,
                    },
                    docFirstPrintCriteria: {
                        ...prevState.docFirstPrintCriteria,
                        invPrinter: printQueueDropdownOptions.length > 0 ? printQueueDropdownOptions[0].value : '',
                        suppPrinter: printQueueDropdownOptions.length > 0 ? printQueueDropdownOptions[0].value : '',
                    }
                }))
            }
        )

        await remoteFileInfoRepo.getFileLoc().then(
            fileLocs => {
                let fileLocationDropdownOptions = fileLocs?.map((fileLoc) => ({
                    dropdownLabel: fileLoc,
                    tagLabel: fileLoc,
                    value: fileLoc,
                })) ?? []
                fileLocationDropdownOptions = _.orderBy(fileLocationDropdownOptions, "dropdownLabel");

                documentPreviewDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        fileLocationDropdownOptions: fileLocationDropdownOptions,
                    },
                    docFirstPrintCriteria: {
                        ...prevState.docFirstPrintCriteria,
                        invFileLoc: fileLocationDropdownOptions.length > 0 ? fileLocationDropdownOptions[0].value : '',
                        suppFileLoc: fileLocationDropdownOptions.length > 0 ? fileLocationDropdownOptions[0].value : '',
                    }
                }))
            }
        )



        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                documentPreviewDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,                        
                        billToCompanyDropdownOptions: [
                            ...companyCodeDropdownOptions],
                    }
                }))
            }
        )

        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOptions:DropdownProps[] = [];
                
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOptions.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOptions.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }                    
                });

                documentPreviewDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOptions                        
                    }
                }))
            }
        )

    }

    const searchDocPreviewData = async(searchCriteria: DocumentPreviewSearchCriteria) => {
        documentPreviewDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: searchCriteria,
                documentPreviewList:[],
                isReadyForFinalize: false,
                isDisapproved: false,
                isWaitForApproval: false,
                isAllowAutoSearch:false,
            }
        })
        await documentPreviewRepo.searchDocPreviewData({
            searchCriteria: searchCriteria,
            firstPrintCriteria: {...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA},
            docHdrList: [],
            docDtlList: [],
            invoiceItemDtlList: [],
            isDocNoBackDateFlag: 'N'   
        }).then((data) => {
            if(data && data.length>0){
                const newData = data.map(entity => ({  
                    ...entity,
                    approvalStatus: ApprovalStatusPolicy().getApprovalStatusValueByKey(entity.approvalStatus as string),
                }));
                documentPreviewDispatch(prevState => {
                    return {
                        ...prevState,
                        documentPreviewList: newData,
                        isAllowAutoSearch:false,
                        selectedRows:[],
                        isReadyForFinalize: false,
                        isDisapproved: false,
                        isWaitForApproval: false,
                        searchStatus: "",
                        searchCriteria: searchCriteria,
                    }
                })
            }else{
                documentPreviewDispatch(prevState => {
                    return {
                        ...prevState,
                        documentPreviewList: [],
                        isAllowAutoSearch:false,
                        selectedRows:[],
                        isReadyForFinalize: false,
                        isDisapproved: false,
                        isWaitForApproval: false,
                        searchCriteria: searchCriteria,
                        searchStatus: ""
                    }
                })
            }
        }).catch((error) => {
            return [];
        })
    }

    const searchInvItemDtl = async(searchCriteria: DocumentPreviewSearchCriteria) => {
        documentPreviewDispatch(prevState => {
            return {
                ...prevState,
                //emptyPoolDatas:[],
                //selectedEmptyPoolDatas: []
            }
        })
        await documentPreviewRepo.searchInvoiceItemDtl({
            searchCriteria: searchCriteria,
            firstPrintCriteria: {...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA},
            docHdrList: [],
            docDtlList: [],
            invoiceItemDtlList: [],
            isDocNoBackDateFlag: 'N'   
        }).then((data) => {
            documentPreviewDispatch(prevState => {
                return {
                    ...prevState,
                    //emptyPoolDatas: data,
                    //selectedEmptyPoolDatas: []
                }
            })
        }).catch((error) => {
            return [];
        })
    }

    const onRemoveSearchCriteria = (fieldName: string, isSearch: boolean = true) => {
        let resetSearchCriteriaPerField = { [fieldName]: EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA[fieldName] }
        
        documentPreviewDispatch(prevState => {
            return {
                ...prevState,
                searchCounter: isSearch ? prevState.searchCounter + 1 : prevState.searchCounter,
                searchCriteria: { ...prevState.searchCriteria, ...resetSearchCriteriaPerField }
            }
        })
    }

    const onRemoveAllSearchCriteria = () => {
        documentPreviewDispatch(prevState => {
            prevState.currentTableRef?.current?.api.setServerSideDatasource({
                getRows: (params: any) => {
                    params.success({
                        rowData: [],
                        rowCount: 0
                    })
                },
            })
            
            return {
                ...prevState, searchCounter: 0, searchCriteria: { ...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA }
            }
        })
    }

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        documentPreviewDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                }
            }
        });
    };
  
    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        documentPreviewDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onCloseScreen = () => {
        document.dispatchEvent(new CustomEvent('closeDocumentPreview'))
    }

    const onShowLoading = () => {
        documentPreviewDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        documentPreviewDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }
  
    const onSearchClick = async() => {
        documentPreviewDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel
            }
        });       
    }

    const onFinalize = async(userName:string,selectedDocPrevDatas: DocumentPreviewEntity[],firstPrintCriteria: DocumentPreviewFirstPrintCriteria) => {
        try {
            // const ids: number[] = selectedDocPrevDatas.map(hdr => hdr?.id).filter((id): id is number  => id !== null);
            const res = await documentPreviewRepo.finalize({
                searchCriteria: {...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA},
                firstPrintCriteria: firstPrintCriteria,
                docHdrList: selectedDocPrevDatas,
                docDtlList: [],
                invoiceItemDtlList: [],
                isDocNoBackDateFlag: 'N'});
            
                if(res.success){
                    documentPreviewDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"finalizeDocSucess":""},    
                            // isShowPrintPad:true,
                            // docFirstPrintCriteria:{
                            //     ...prevState.docFirstPrintCriteria,
                            //     finalizedBy:userName,
                            // }
                        }
                    })
                }else{
                    documentPreviewDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"finalizeDocFail":res.data}    
                        }
                    })
                }
                return res.success;
        } catch (error) {
            documentPreviewDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"finalizeDocFail":"finalize error."}    
                }
            })
        }
    }

    const onReject = async(selectedDocPrevDatas: DocumentPreviewEntity[]) => {
        await documentPreviewRepo.reject({
            searchCriteria: {...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA},
            firstPrintCriteria: {...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA},
            docHdrList: selectedDocPrevDatas,
            docDtlList: [],
            invoiceItemDtlList: [],
            isDocNoBackDateFlag: 'N'}).then((data) => {
                /*if(data && data === "success"){
                    documentPreviewDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"finalizeDocSucess":""}    
                        }
                    })
                }else{
                    documentPreviewDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"finalizeDocFail":data}    
                        }
                    })
                }*/
            }).catch((error) => {
                documentPreviewDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"rejectDocFail":"reject error."}    
                    }
                })
        })     
    }

    const onSavePoNo = async(selectedDocPrevDatas: DocumentPreviewEntity[]) => {
        await documentPreviewRepo.savePoNo({
            searchCriteria: {...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA},
            firstPrintCriteria: {...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA},
            docHdrList: selectedDocPrevDatas,
            docDtlList: [],
            invoiceItemDtlList: [],
            isDocNoBackDateFlag: 'N'}).then((data) => {
                /*if(data && data === "success"){
                    documentPreviewDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"finalizeDocSucess":""}    
                        }
                    })
                }else{
                    documentPreviewDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"finalizeDocFail":data}    
                        }
                    })
                }*/
            }).catch((error) => {
                documentPreviewDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"savePoNoFail":"save P.O. No. error."}    
                    }
                })
        })     
    }

    const onApply = async(selectedInvtemDtlDatas: DocumentPreviewDetailEntity[]) => {
        await documentPreviewRepo.apply({
            searchCriteria: {...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA},
            firstPrintCriteria: {...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA},
            docHdrList: [],
            docDtlList: [],
            invoiceItemDtlList: selectedInvtemDtlDatas,
            isDocNoBackDateFlag: 'N'}).then((data) => {
                /*if(data && data === "success"){
                    documentPreviewDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"finalizeDocSucess":""}    
                        }
                    })
                }else{
                    documentPreviewDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"finalizeDocFail":data}    
                        }
                    })
                }*/
            }).catch((error) => {
                documentPreviewDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"applyFail":"apply error."}    
                    }
                })
        })     
    }

    const searchDataForMove = async(selectedInvtemDtlDatas: DocumentPreviewDetailEntity[]) => {
        await documentPreviewRepo.searchDataForMove({
            searchCriteria: {...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA},
            firstPrintCriteria: {...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA},
            docHdrList: [],
            docDtlList: [],
            invoiceItemDtlList: selectedInvtemDtlDatas,
            isDocNoBackDateFlag: 'N'}).then((data) => {
                /*if(data && data === "success"){
                    documentPreviewDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"finalizeDocSucess":""}    
                        }
                    })
                }else{
                    documentPreviewDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"finalizeDocFail":data}    
                        }
                    })
                }*/
            }).catch((error) => {
                documentPreviewDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"savePoNoFail":"save P.O. No. error."}    
                    }
                })
        })     
    }

    const addOrApplyDocDataForMove = async(selectedDocHdrDatas: DocumentPreviewEntity[], selectedDocDtlDatas: DocumentPreviewDetailEntity[]) => {
        await documentPreviewRepo.addOrApplyDocDataForMove({
            searchCriteria: {...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA},
            firstPrintCriteria: {...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA},
            docHdrList: selectedDocHdrDatas,
            docDtlList: [],
            invoiceItemDtlList: selectedDocDtlDatas,
            isDocNoBackDateFlag: 'N'}).then((data) => {
                /*if(data && data === "success"){
                    documentPreviewDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"finalizeDocSucess":""}    
                        }
                    })
                }else{
                    documentPreviewDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"finalizeDocFail":data}    
                        }
                    })
                }*/
            }).catch((error) => {
                documentPreviewDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"savePoNoFail":"save P.O. No. error."}    
                    }
                })
        })     
    }

    const previewDocReport = async(selectedDocHdrDatas: DocumentPreviewEntity[], isDownloadRpt:boolean) => { 
                    
            await documentPreviewRepo.previewDocReport({
                searchCriteria: {...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA},
                firstPrintCriteria: {...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA},
                docHdrList: selectedDocHdrDatas,
                docDtlList: [],
                invoiceItemDtlList: [],
                isDocNoBackDateFlag: 'N'}).then((data) => {
                    prevOrDownloadRpt(data, isDownloadRpt);
                }).catch((error) => {
                    documentPreviewDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"previewDocReport":"preview document report error."}    
                        }
                    })
            })   
    }   

    const previewSupportingReport = async(selectedDocHdrDatas: DocumentPreviewEntity[], isDownloadRpt:boolean) => {
        
            await documentPreviewRepo.previewSupportingReport({
                searchCriteria: {...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA},
                firstPrintCriteria: {...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA},
                docHdrList: selectedDocHdrDatas,
                docDtlList: [],
                invoiceItemDtlList: [],
                isDocNoBackDateFlag: 'N'}).then((data) => {                    
                    const isPreviewRpt =  prevOrDownloadRpt(data, isDownloadRpt);

                    if(!isPreviewRpt){
                        documentPreviewDispatch(prevState => {
                            return {
                                ...prevState,
                                allFormState: {"previewReportFail":"Invoice/Credit Note and/or Supporting Reports cannot be found."}    
                            }
                        })
                    }
                }).catch((error) => {
                    documentPreviewDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"previewSupportingReport":"preview supporting report error."}    
                        }
                    })
            })
    }

    const prevOrDownloadRpt = (data: any, isDownloadRpt: boolean) => {
        if(data != null){                        
            for (const key in data) {
            
                if (data[key] != null && data[key] !== undefined) {
                    const tempData = data[key] as string;
                
                    let base64 = tempData
                    let bstr = atob(base64)
                    let n = bstr.length
                    let u8arr = new Uint8Array(n)
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n)
                    }
                    const blob = new Blob([u8arr], { type: 'application/pdf' } );
                    const url = URL.createObjectURL(blob);

                    if(isDownloadRpt){
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = key + ".pdf";
                        a.style.display = 'none';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                    } else { 
                        window.open(url, '_blank'); 
                        URL.revokeObjectURL(url);   
                    }   
                }                  
            };
            
            return true;
        }

        return false;
    }
   
    const onSearchInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string, toUpperCase:boolean=false) => {
        documentPreviewDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: toUpperCase ? e.target.value.toString().toUpperCase() : e.target.value,
            }
        }))
    };

    const updateSelectedRows = async (rows:any[]) => {
        documentPreviewDispatch(prevState => {
            return {
                ...prevState,
                selectedRows: rows,
            }
        })
    }

    const onTableInit = () => {
        dynamicTableRepo.getDynamicTableCols('DOC_PRE').then((cols) => {
            let dateFieldList:string[] = [];
            let dateTimeFieldList:string[] = [];
            let newCols = _.orderBy((cols).filter(col =>  col['field'] !== "id").map((col): DynamicTableEntity => {
                if(col['field'] === "previewRefNo"){
                    return {
                        ...col,
                        headerName: getDocumentPreviewValue(col['headerName'] as keyof typeof DynamicColumnNameMapping.documentPreview)??'',
                        width: 200,
                        pinned: "left",
                        checkboxSelection: true,
                        headerCheckboxSelection: true,
                    }
                }else{
                    return {
                        ...col,
                        headerName: getDocumentPreviewValue(col['headerName'] as keyof typeof DynamicColumnNameMapping.documentPreview)??'',
                        width: 200,
                    }
                }
            }),['colIndex']).map((col, index) => {
                const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};
            
                return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
            });

            documentPreviewDispatch(prevState => {
                return {
                    ...prevState,
                    dynamicTableColumns: newCols,
                }
            })
        })
    }

    const onSearchCriteriaResetClick = () => {
        documentPreviewDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA
            }
        })
    }

    const onRowDoubleClick = (docHdr: DocumentPreviewEntity) => {
        documentPreviewDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: true,
                currentSelectedData: docHdr
            }
        })
    }

    const onGroupButtonClick = async (buttonId: string) => {
        documentPreviewDispatch(prevState => {
            return {
                ...prevState,
                selectedRows: [],
                documentPreviewList: []
            }
        })
        let isReadyForFinalize = false;
        let isDisapproved = false;
        let isWaitForApproval = false;
        let status = "";
        switch (buttonId) {  
            case 'isWaitForApproval':  
                isWaitForApproval = true;
                isDisapproved = false;
                isReadyForFinalize = false;
                status = "WFA";
                break;  
                case 'isDisapproved':  
                isWaitForApproval = false;
                isDisapproved = true;
                isReadyForFinalize = false;
                status = "REJ";
                break;  
            case 'isReadyForFinalize':  
                isWaitForApproval = false;
                isDisapproved = false;
                isReadyForFinalize = true;
                break;  
            default:  
        }  

        await searchAllDocPreviewData().then((data) =>{
            documentPreviewDispatch(prevState => {
                let newList = [];
                if(status === ""){
                    newList = prevState.allDocumentPreviewList.filter(entity => ApprovalStatusPolicy().getApprovalStatusKeyByValue(entity.approvalStatus as string) !== "WFA" && ApprovalStatusPolicy().getApprovalStatusKeyByValue(entity.approvalStatus as string) !== "REJ");
                }else{
                    newList = prevState.allDocumentPreviewList.filter(entity => ApprovalStatusPolicy().getApprovalStatusKeyByValue(entity.approvalStatus as string) === status);
                }
                return {
                    ...prevState,
                    selectedRows:[],
                    isReadyForFinalize: isReadyForFinalize,
                    isDisapproved: isDisapproved,
                    isWaitForApproval: isWaitForApproval,
                    documentPreviewList: newList,
                    searchStatus: buttonId,
                    isAllowAutoSearch: false
                }
            })
        })
        
    }

    const onApprove = async (ids: number[]) => {
        return await documentApprovalRepo.onApprove(ids);
    }
    const onDisapprove = async (ids: number[]) => {
        return await documentApprovalRepo.onDisapprove(ids);
    }

    const searchAllDocPreviewData = async() => {
        await documentPreviewRepo.searchDocPreviewData({
            searchCriteria: {...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA},
            firstPrintCriteria: {...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA},
            docHdrList: [],
            docDtlList: [],
            invoiceItemDtlList: [],
            isDocNoBackDateFlag: 'N'   
        }).then((data) => {
            if(data && data.length>0){
                let isWaitForApprovalCount = 0;
                let isDisapprovedCount = 0;
                let isReadyForFinalizeCount = 0;
                isWaitForApprovalCount = data.filter(entity => entity.approvalStatus === "WFA").length;
                isDisapprovedCount = data.filter(entity => entity.approvalStatus === "REJ").length;
                isReadyForFinalizeCount = data.filter(entity => entity.approvalStatus !== "WFA" && entity.approvalStatus !== "REJ").length;
                const newData = data.map(entity => ({  
                    ...entity,
                    approvalStatus: ApprovalStatusPolicy().getApprovalStatusValueByKey(entity.approvalStatus as string),
                }));
                documentPreviewDispatch(prevState => {
                    return {
                        ...prevState,
                        allDocumentPreviewList: newData,
                        isWaitForApprovalCount: isWaitForApprovalCount,
                        isDisapprovedCount: isDisapprovedCount,
                        isReadyForFinalizeCount: isReadyForFinalizeCount,
                    }
                })
            }else{
                documentPreviewDispatch(prevState => {
                    return {
                        ...prevState,
                        allDocumentPreviewList: [],
                        isWaitForApproval: false,
                        isDisapproved: false,
                        isReadyForFinalize: false,
                        isWaitForApprovalCount: 0,
                        isDisapprovedCount: 0,
                        isReadyForFinalizeCount: 0,
                    }
                })
            }
        }).catch((error) => {
            return [];
        })
    }

    const onEditInputTextChange = (e: any) => {
        documentPreviewDispatch(prevState => ({
            ...prevState,
                poNo: e.target.value
        }))
    };
    
    const onEditCloseClick = () => {
        documentPreviewDispatch(prevState => {
            return {
                ...prevState,
                isShowEditPanel: false,
                poNo: "",
            }
        }); 
    }

    const onEditClick = () => {
        documentPreviewDispatch(prevState => {
            return {
                ...prevState,
                isShowEditPanel: true,
                isShowCriteriaPanel: false,
            }
        }); 
    }

    const onEditSaveClick = async(poNo: string,selectDatas: DocumentPreviewEntity[]) => {
        if(selectDatas && selectDatas.length > 0 && poNo){
            selectDatas = selectDatas.map((data) => {
                    let keyData = {...data};
                    keyData.poNo = poNo;
                    return {...data, ...keyData};    
            })
            await onSavePoNo(selectDatas).then((data) => {
                documentPreviewDispatch(prevState => {
                    return {
                        ...prevState,
                        isShowEditPanel: false,
                        poNo: "",
                    }
                })
            }).catch((error) => {
                return [];
            })
        }else{
            documentPreviewDispatch(prevState => {
                return {
                    ...prevState,
                    isShowEditPanel: false,
                    poNo: "",
                }
            })
        }
    }

    const onModalDropdownChange = (e: any, fieldName: string) => {
        documentPreviewDispatch(prevState => ({
            ...prevState,
            docFirstPrintCriteria: {
                ...prevState.docFirstPrintCriteria,
                [fieldName]: e?.value 
            }
        }))
    }


    const onShowPrintAndSent = async (userName:string,selectedDocPrevDatas: DocumentPreviewEntity[]) => {
        documentPreviewDispatch(prevState => {
            const fileLocation = prevState.dynamicOptions.fileLocationDropdownOptions[0].value;
            const printQueue = prevState.dynamicOptions.printQueueDropdownOptions[0].value;
            return {
                ...prevState,
                isShowPrintPad:true,
                selectedRows:selectedDocPrevDatas,
                docFirstPrintCriteria:{
                    ...prevState.docFirstPrintCriteria,
                    finalizedBy:userName,
                    invFileLoc:fileLocation,
                    suppFileLoc:fileLocation,
                    invPrinter:printQueue,
                    suppPrinter:printQueue,
                    
                }
            }
        })
    }

    const onPrintAndSent = async (userName:string,ids: number[], selectedRows: DocumentPreviewEntity[],
        docFirstPrintCriteria: DocumentPreviewFirstPrintCriteria,searchCriteria: DocumentPreviewSearchCriteria,searchStatus:string) => {
            closeConfirmModal();
            docFirstPrintCriteria = {...docFirstPrintCriteria,
                docHdrIds:ids
            }
        onFinalize(userName,selectedRows,docFirstPrintCriteria).then(async (data) => {
            // documentPreviewRepo.printAndSent(docFirstPrintCriteria);
            console.log("onFinalizeonFinalizeonFinalizeonFinalizeonFinalize====="+data);
            if(data){
                if(searchStatus !== ""){
                    onGroupButtonClick(searchStatus).then((data) => {
                    });
                }else{
                    await searchDocPreviewData(searchCriteria).then((data) => {
                    });
                }
                documentPreviewDispatch(prevState => ({
                    ...prevState,
                    isPrinting: false,
                    docFirstPrintCriteria: {...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA}
                }))
            }

        });
        // await documentPreviewRepo.printAndSent(docFirstPrintCriteria).then(async (res) => {
        //     if(res.success){
        //         if(searchStatus !== ""){
        //                     onGroupButtonClick(searchStatus).then((data) => {
        //                     });
        //         }else{
        //             await searchDocPreviewData(searchCriteria).then((data) => {
        //             });
        //         }
        //         console.log('Success to send invoice print.')
        //     }else{
        //         console.log('Failed to send invoice print.')
        //     }  
        //     documentPreviewDispatch(prevState => ({
        //         ...prevState,
        //         docFirstPrintCriteria: {...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA}
        //     }))


        // });
    }

    const closeConfirmModal = () => {
        documentPreviewDispatch(prevState => ({
            ...prevState,
            isShowPrintPad: false,
            isPrinting: true,
        }))
    };

    const cancelConfirmModal = () => {
        documentPreviewDispatch(prevState => ({
            ...prevState,
            isShowPrintPad: false,
            isPrinting: false,
        }))
    };

    return {
        searchDocPreviewData: searchDocPreviewData,
        searchInvItemDtl: searchInvItemDtl,
        loadDropdownOption: loadDropdownOption,
        onRemoveSearchCriteria: onRemoveSearchCriteria,
        onRemoveAllSearchCriteria: onRemoveAllSearchCriteria,        
        onMultipleDropdownChange: onMultipleDropdownChange,
        onDateRangeChange: onDateRangeChange,
        onSearchClick: onSearchClick,
        onCloseScreen: onCloseScreen,        
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onFinalize: onFinalize,
        onSavePoNo: onSavePoNo,
        onReject: onReject,
        onApply: onApply,
        searchDataForMove: searchDataForMove,
        addOrApplyDocDataForMove: addOrApplyDocDataForMove,
        previewDocReport: previewDocReport,
        //downloadDocReport: downloadDocReport,
        previewSupportingReport: previewSupportingReport,
        onSearchInputTextChange: onSearchInputTextChange,
        updateSelectedRows: updateSelectedRows,
        onTableInit: onTableInit,
        onSearchCriteriaResetClick: onSearchCriteriaResetClick,
        onRowDoubleClick: onRowDoubleClick,
        onGroupButtonClick: onGroupButtonClick,
        onApprove: onApprove,
        onDisapprove: onDisapprove,
        searchAllDocPreviewData: searchAllDocPreviewData,
        onEditInputTextChange: onEditInputTextChange,
        onEditCloseClick: onEditCloseClick,
        onEditSaveClick: onEditSaveClick,
        onEditClick: onEditClick,
        //downloadDocReport,downloadDocReport
        onModalDropdownChange: onModalDropdownChange,
        closeConfirmModal: closeConfirmModal,
        cancelConfirmModal: cancelConfirmModal,
        onPrintAndSent: onPrintAndSent,
        onShowPrintAndSent: onShowPrintAndSent,
    }
}