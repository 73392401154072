import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { DynamicTableRepoImpl } from "domain/repository/Common/DynamicTableRepoImpl";
import { PrintQueueRepoImpl } from "domain/repository/Common/PrintQueueRepoImpl";
import { RemoteFileInfoRepoImpl } from "domain/repository/Common/RemoteFileInfoRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { DocumentApprovalRepoImpl } from "domain/repository/DocumentApproval/DocumentApprovalRepoImpl";
import { DocumentPreviewRepoImpl } from "domain/repository/DocumentPreview/DocumentPreviewRepoImpl";
import { UserRepoImpl } from "domain/repository/User/UserRepoImpl";
import { useDocumentPreviewTracked } from "presentation/store/DocumentPreview/DocumentPreviewProvider";
import { DocumentPreviewVM } from "presentation/viewModel/DocumentPreview/DocumentPreviewVM";
import { useMemo } from "react";

export const useDocumentPreviewVM = () => {
    const [, setDocumentPreviewState] = useDocumentPreviewTracked();
    const documentPreviewVM = useMemo(() =>
        DocumentPreviewVM({
            dispatch: [setDocumentPreviewState],
            documentPreviewRepo: DocumentPreviewRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            dynamicTableRepo: DynamicTableRepoImpl(),
            documentApprovalRepo: DocumentApprovalRepoImpl(),
            userRepo: UserRepoImpl(),
            printQueueRepo: PrintQueueRepoImpl(),
            remoteFileInfoRepo: RemoteFileInfoRepoImpl(),
        }), [setDocumentPreviewState])

    return documentPreviewVM
}

