import { DocumentPreviewSearchCriteria } from "domain/entity/DocumentPreview/DocumentPreviewSearchCriteria";
import { DocumentPreviewConstant } from "presentation/constant/DocumentPreview/DocumentPreviewConstant";
import { useDocumentPreviewVM } from "presentation/hook/DocumentPreview/useDocumentPreviewVM";
import { useDocumentPreviewTracked } from "presentation/store/DocumentPreview/DocumentPreviewProvider";
import { useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import DocumentPreviewSearchForm from "./DocumentPreviewSearchForm";

const DocumentPreviewSearchPanel = () => {
    const DOCUMENT_PREVIEW_CONSTANT = DocumentPreviewConstant.Search;
    const [documentPreviewState] = useDocumentPreviewTracked();
    const documentPreviewVM = useDocumentPreviewVM();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const resetButtonClicked = useCallback(() => {
        documentPreviewVM.onSearchCriteriaResetClick();
    }, [documentPreviewVM]);
    
    const searchButtonClicked = useCallback((criteria:DocumentPreviewSearchCriteria) => {
        setIsLoading(true);
        // let newSearchCriteria = {...criteria} 
                        criteria = { ...criteria, 
                            approvalStatus:""
                        };
        documentPreviewVM.searchDocPreviewData(criteria).then((data) => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
        
    }, [documentPreviewVM])

    return (
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{DOCUMENT_PREVIEW_CONSTANT.SEARCH_CRITERIA}</SidebarTitle>
                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={documentPreviewVM.onSearchClick} />
                        </SidebarActionCross>
                    </Sidebarheader>                
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" /> }

            <div className={'add-edit-form'} style={{ maxHeight:'75vh' , height:'75vh', overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        
                        {/* Stack Form Start */}
                        <DocumentPreviewSearchForm/>
                    </div>                  
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked(documentPreviewState.searchCriteria)} />
            </SidebarActionBar>
        </div>
    );
}

export default DocumentPreviewSearchPanel;
