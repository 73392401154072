import { SelectionChangedEvent } from "ag-grid-community";
import { DocumentPreviewEntity } from "domain/entity/DocumentPreview/DocumentPreviewEntity";
import { EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA } from "domain/entity/DocumentPreview/DocumentPreviewSearchCriteria";
import moment from "moment";
import { transferRowData } from "presentation/constant/DocumentPreview/DocumentPreviewColumnDefinition";
import { DocumentPreviewConstant } from "presentation/constant/DocumentPreview/DocumentPreviewConstant";
import { useDocumentPreviewVM } from "presentation/hook/DocumentPreview/useDocumentPreviewVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useDocumentPreviewTracked } from "presentation/store/DocumentPreview/DocumentPreviewProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Core } from "veronica-ui-component";
import { GroupButtons, HPHTable, Loader } from "veronica-ui-component/dist/component/core";


const DocumentPreviewTablePanel:React.FC = () => {
    const [documentPreviewState] = useDocumentPreviewTracked();
    const documentPreviewVM = useDocumentPreviewVM();
    const gridRef: any = useRef(null);
    const {searchStatus,documentPreviewList,isWaitForApproval,isDisapproved,isReadyForFinalize,isDisapprovedCount,isWaitForApprovalCount,isReadyForFinalizeCount} = documentPreviewState;
    const DOCUMENT_PREVIEW_CONSTANT = DocumentPreviewConstant.Title;
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [anaInfoState] = useANAInfoTracked();
    
    useEffect(() => {
        const columnDefs = (documentPreviewState.dynamicTableColumns.slice());
        
        if (!documentPreviewState.selectedRows ||
            documentPreviewState.selectedRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
    })

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        documentPreviewVM.updateSelectedRows(selectedRows);
      }, [documentPreviewVM])

      const handleRowDoubleClick = useCallback((docHdr: DocumentPreviewEntity) => {
        documentPreviewVM.onRowDoubleClick(docHdr);
    }, [documentPreviewVM])

    const handleGroupButtonClick = useCallback(async (buttonId: string) => {
        documentPreviewVM.onShowLoading();
        if(searchStatus && buttonId === searchStatus){
            let newSearchCriteria = null;
            newSearchCriteria = {...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA} 
            newSearchCriteria = { ...newSearchCriteria, 
                genBy: anaInfoState.userName, 
                genDateFrom: moment().subtract(10, "days").startOf("day").toDate(),
                genDateTo: moment().endOf("day").toDate(), 
            };
            documentPreviewVM.searchAllDocPreviewData();
            await documentPreviewVM.searchDocPreviewData(newSearchCriteria).then((data) => {            
                documentPreviewVM.onHideLoading();
            }).catch(error => {            
                documentPreviewVM.onHideLoading();
            }).finally(() => {
                documentPreviewVM.onHideLoading();
            })
        }else{
            await documentPreviewVM.onGroupButtonClick(buttonId).then((data) => {
                documentPreviewVM.onHideLoading();
            }).catch(error => {            
                documentPreviewVM.onHideLoading();
            }).finally(() => {
                documentPreviewVM.onHideLoading();
            })
        }
    }, [anaInfoState.userName, documentPreviewVM, searchStatus]);
    
    const template = useMemo(() => {
        return(
        <>
          <Core.CheckButton
            checked={isReadyForFinalize}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleGroupButtonClick(e.target.id) }}
            showBadge={true}
            badgeValue={isReadyForFinalizeCount>999?'999+':isReadyForFinalizeCount}
            disabled={isReadyForFinalizeCount===0}
            size="medium"
            inputId="isReadyForFinalize"
            label={DOCUMENT_PREVIEW_CONSTANT.NOT_APPROVED_BUT} 
          />
          <Core.CheckButton
            checked={isDisapproved}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleGroupButtonClick(e.target.id) }}
            showBadge={true}
            badgeValue={isDisapprovedCount>999?'999+':isDisapprovedCount}
            disabled={isDisapprovedCount===0}
            size="medium"
            inputId="isDisapproved"
            label={DOCUMENT_PREVIEW_CONSTANT.DISAPPROVED_BUT} 
          />
          <Core.CheckButton
            checked={isWaitForApproval}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleGroupButtonClick(e.target.id) }}
            showBadge={true}
            badgeValue={isWaitForApprovalCount>999?'999+':isWaitForApprovalCount}
            disabled={isWaitForApprovalCount===0}
            size="medium"
            inputId="isWaitForApproval"
            label={DOCUMENT_PREVIEW_CONSTANT.WAIT_APPROVAL_BUT} 
          />
      </>
  )},[DOCUMENT_PREVIEW_CONSTANT.DISAPPROVED_BUT, DOCUMENT_PREVIEW_CONSTANT.NOT_APPROVED_BUT, DOCUMENT_PREVIEW_CONSTANT.WAIT_APPROVAL_BUT, handleGroupButtonClick, isDisapproved, isDisapprovedCount, isReadyForFinalize, isReadyForFinalizeCount, isWaitForApproval, isWaitForApprovalCount])
  
    const memoDocumentPreviewTable = useMemo(() => {

        return (
        <HPHTable
            id='document-preview-table'
            isNewColumnSetting={true}
            columns={documentPreviewState.dynamicTableColumns.slice()}
            data={transferRowData(documentPreviewList??[])}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            rowSelection={"multiple"}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any, docHdr: DocumentPreviewEntity) => handleRowDoubleClick(docHdr)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
            headerLabel={
                <GroupButtons
                    alignment="single-selection"
                    errorMessage=""
                    label=""
                    children={template}
                    />
            }
        />
        );
    },[documentPreviewList, documentPreviewState.dynamicTableColumns, handleRowDoubleClick, handleSelectionChange, template])

    return <><TableWrapper>
        {documentPreviewState.isLoading && <Loader Indicator="Spinner" size="Medium" /> }
            {memoDocumentPreviewTable}
        </TableWrapper></>;
}

export default memo(DocumentPreviewTablePanel);
