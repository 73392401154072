import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { DocumentPreviewProvider } from "presentation/store/DocumentPreview/DocumentPreviewProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { DocumentPreviewMDView } from "presentation/view/section/DocumentPreview/DocumentPreviewMDView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const DocumentPreviewContMain = () => {
    return <ANAInfoWrapper permission={Permission.DOCUMENT_PREVIEW}>
        <MessageBarWrapper>
            <DocumentPreviewProvider>
                <GridStyles/>
                <DocumentPreviewMDView/>
            </DocumentPreviewProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default DocumentPreviewContMain;