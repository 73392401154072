export const ApprovalStatusPolicy =() => {

    const ITEM_KEY_NA = "NA";
	const ITEM_KEY_WFA = "WFA";
	const ITEM_KEY_APP = "APP";
	const ITEM_KEY_REJ = "REJ";


    const ITEM_VAL_NA = "Not available";
	const ITEM_VAL_WFA = "Pending Approval";
	const ITEM_VAL_APP = "Approved";
	const ITEM_VAL_REJ = "Rejected";

    
    const getApprovalStatusValueByKey = (text: string) => {
        if(!text){
            return null;
        }
        if (ITEM_KEY_NA === text) {
            return ITEM_VAL_NA;
        } else if (ITEM_KEY_WFA === text) {
            return ITEM_VAL_WFA;
        } else if (ITEM_KEY_APP === text) {
            return ITEM_VAL_APP;
        } else if (ITEM_KEY_REJ === text) {
            return ITEM_VAL_REJ;
        } 
        return null;
    }
    const getApprovalStatusKeyByValue = (text: string) => {
        if(!text){
            return null;
        }
        if (ITEM_VAL_NA === text) {
            return ITEM_KEY_NA;
        } else if (ITEM_VAL_WFA === text) {
            return ITEM_KEY_WFA;
        } else if (ITEM_VAL_APP === text) {
            return ITEM_KEY_APP;
        } else if (ITEM_VAL_REJ === text) {
            return ITEM_KEY_REJ;
        } 
        return null;
    }
    return {
        getApprovalStatusValueByKey: getApprovalStatusValueByKey,
        getApprovalStatusKeyByValue: getApprovalStatusKeyByValue,
    }
}
