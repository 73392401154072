import { EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA } from "domain/entity/DocumentPreview/DocumentPreviewSearchCriteria";
import moment from "moment";
import { useDocumentPreviewVM } from "presentation/hook/DocumentPreview/useDocumentPreviewVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useDocumentPreviewTracked } from "presentation/store/DocumentPreview/DocumentPreviewProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import DocumentPreviewTitleBar from "./DocumentPreviewTitleBar";
import DocumentPreviewSearchPanel from "./LeftPanel/DocumentPreviewSearchPanel";
import DocumentPreviewFirstPrintModal from "./RightPanel/DocumentPreviewFirstPrintModal";
import DocumentPreviewRightPanel from "./RightPanel/DocumentPreviewRightPanel";


const DocumentPreviewMaintenance:React.FC = () => {
    const [documentPreviewState] = useDocumentPreviewTracked();
    const documentPreviewVM = useDocumentPreviewVM();
    const { isShowCriteriaPanel,isAllowAutoSearch,isBackFromDetail,searchCriteria,isReadyForFinalize,isWaitForApproval,isDisapproved,searchStatus } = documentPreviewState;
    const [isLoading, setIsLoading] = useState(false);
    const [anaInfoState] = useANAInfoTracked();
    useEffect(() => {
        const initialScreen = async() => {   
            setIsLoading(true);
            try {     
                const results = await Promise.allSettled([
                    documentPreviewVM.onTableInit(),
                    documentPreviewVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
                        console.log(`Table init successful!`);
                    } 
                })
            } catch(error) {
                setIsLoading(false);
            }
        }

        isAllowAutoSearch && initialScreen().then(async () => {  
            try {     
                await documentPreviewVM.searchAllDocPreviewData();
                if(searchStatus === ""){
                    let newSearchCriteria = null;
                    if(isBackFromDetail){
                        newSearchCriteria = {...searchCriteria};
                    }else{
                        newSearchCriteria = {...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA} 
                        newSearchCriteria = { ...newSearchCriteria, 
                            genBy: anaInfoState.userName, 
                            genDateFrom: moment().subtract(10, "days").startOf("day").toDate(),
                            genDateTo: moment().endOf("day").toDate(), 
                        };
                    }
                    await documentPreviewVM.searchDocPreviewData(newSearchCriteria).then((data) => {            
                        setIsLoading(false);
                    }).catch(error => {            
                        setIsLoading(false);
                    }).finally(() => {
                        setIsLoading(false);
                    })
                }else{
                    documentPreviewVM.onGroupButtonClick(searchStatus).then((data) => {
                        setIsLoading(false);
                    }).catch(error => {            
                        setIsLoading(false);
                    }).finally(() => {
                        setIsLoading(false);
                    })
                }

            } catch(error) {
                setIsLoading(false);
            }
            }).catch(error => {            
                setIsLoading(false);
            }).finally(() => {
                setIsLoading(false);
            })
        
    }, [anaInfoState.userName, documentPreviewVM, isAllowAutoSearch, isBackFromDetail, isDisapproved, isReadyForFinalize, isWaitForApproval, searchCriteria, searchStatus])


    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <>
        <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}>
           <DocumentPreviewTitleBar/>
           {documentPreviewState.isPrinting && <Loader Indicator="Spinner" size="Medium" /> }
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowCriteriaPanel?"25%":"0%"}
                rightSectionWidth={isShowCriteriaPanel?"75%":"100%"}
                leftChildren={<DocumentPreviewSearchPanel/>}
                rightChildren={<DocumentPreviewRightPanel/>} 
                />
        <div className='im-charge-data-search-confirm-modal-container'>
            { <DocumentPreviewFirstPrintModal/> }

        </div>
        </div>
    </>
}

export default memo(DocumentPreviewMaintenance);