import { DocumentPreviewConstant } from "presentation/constant/DocumentPreview/DocumentPreviewConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { useDocumentPreviewVM } from "presentation/hook/DocumentPreview/useDocumentPreviewVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useDocumentPreviewTracked } from "presentation/store/DocumentPreview/DocumentPreviewProvider";
import { memo, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import { DocumentPreviewEditForm } from "./DocumentPreviewEditForm";


const DocumentPreviewEditPanel = () => {
    const [documentPreviewState] = useDocumentPreviewTracked();
    const documentPreviewVM = useDocumentPreviewVM();
  const messageBarVM = useMessageBarVM();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const saveButtonClicked = async() => {
    setIsLoading(true);
    await documentPreviewVM.onEditSaveClick(documentPreviewState.poNo,documentPreviewState.selectedRows).then(async (data) => {
            messageBarVM.showSuccess(MessageConstant.common.UPDATED_DATA_SUCCESSFUL);
            if(documentPreviewState.searchStatus !== ""){
                documentPreviewVM.onGroupButtonClick(documentPreviewState.searchStatus).then((data) => {
                    setIsLoading(false);
                });
            }else{
                await documentPreviewVM.searchDocPreviewData(documentPreviewState.searchCriteria).then((data) => {
                    setIsLoading(false);
                });
            }
        setIsLoading(false);
    }).catch(() => {
        setIsLoading(false);
    })
  }

  const closeButtonClicked = async() => {
    documentPreviewVM.onEditCloseClick();
  }


  return (
    <div className='side-form-content-wrapper'>
        <div className={'flex-row'}>
            <div className={'flex-row-item flex-row-item-full-width'}>
                <Sidebarheader>
                    <SidebarTitle>
                        {DocumentPreviewConstant.Title.UPDATE}
                    </SidebarTitle>
                    <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={closeButtonClicked} />
                        </SidebarActionCross>
                </Sidebarheader>
            </div>
        </div>
        {isLoading && 
                <Loader Indicator="Spinner" size="Medium"/>}
        {!isLoading && <>   
        <div className={'add-edit-form'} style={{ height:'75vh', maxHeight:'75vh' , overflow: 'auto'}}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <DocumentPreviewEditForm/>
                </div>
            </div>
        </div>
        <SidebarActionBar>
            <HPHButton label={'Close'} size={'Small'} theme={'Secondary'} onClick={closeButtonClicked} />
            <HPHButton label={'Save'} size={'Small'} theme={'Primary'} onClick={saveButtonClicked} />
        </SidebarActionBar>
        </>}
    </div>
);
}

export default memo(DocumentPreviewEditPanel);