import { useDocumentPreviewTracked } from "presentation/store/DocumentPreview/DocumentPreviewProvider";
import DocumentPreviewDetailContMain from "presentation/view/container/DocumentPreview/DocumentPreviewDetailContMain";
import React from "react";
import DocumentPreviewMaintenance from "./DocumentPreviewMaintenance";

export const DocumentPreviewMDView: React.FC = () => {
    const [documentPreviewState] = useDocumentPreviewTracked();
     const isShowDetail = documentPreviewState.isShowDetail;
   return <>
        {!isShowDetail 
            && <div className="main-comp-wrapper" >
            <DocumentPreviewMaintenance/>
        </div>}
    {
        isShowDetail && <div className="main-comp-wrapper" ><DocumentPreviewDetailContMain/> </div>
    } 

    </>
}
