import { useDocumentPreviewVM } from "presentation/hook/DocumentPreview/useDocumentPreviewVM";
import { useDocumentPreviewTracked } from "presentation/store/DocumentPreview/DocumentPreviewProvider";
import { InputField } from "veronica-ui-component/dist/component/core";


export const DocumentPreviewEditForm = () => {
  const [documentPreviewState] = useDocumentPreviewTracked();
  const documentPreviewVM = useDocumentPreviewVM();
  const poNo = documentPreviewState.poNo;
  return (
      <>
          <div className={'im-flex-row'}>
            <div className='im-flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label='Update P.O. No.'
                type="text"
                value={poNo}
                onChange={(e: any) => documentPreviewVM.onEditInputTextChange(e)}/>
            </div>
          </div>
      </>
  )
}
